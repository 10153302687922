.form-objectives-update * {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  white-space: normal;
  padding: 0;
  margin: 0;
}

.form-objectives-update-root {
  padding: 16px;
  margin: 16px;
}

.form-objectives-update.body h2.main-title {
  font-size: 32px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
}

.form-objectives-update.body .section {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.24);
  background: #fff;
  padding: 0;
}

.form-objectives-update.body .description {
  padding: 24px 32px;
  display: flex;
  gap: 54px;
}

.form-objectives-update.body .description h3 {
  padding-bottom: 0;
}

.form-objectives-update.body .title-section.section {
  padding: 24px 32px;
}

.form-objectives-update.body .needsOutcomes {
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 54px;
  padding: 24px 32px 48px 32px;
}

.form-objectives-update.body .outcomes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-objectives-update.body .questionAnswers {
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 54px;
  padding: 0 32px 64px 32px;
}

.form-objectives-update.body .question {
  padding-bottom: 8px;
}

.form-objectives-update.body .questionAnswers:last-child {
  padding-bottom: 40px;
}

.form-objectives-update.body .answers {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.form-objectives-update.body ol {
  padding-left: 14px;
}

.form-objectives-update.body li {
  padding-bottom: 16px;
}

.form-objectives-update.body .needs-answer {
  padding: 24px 32px 48px 32px;
}

.form-objectives-update.body h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
  padding-bottom: 12px;
}

.form-objectives-update.body h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  letter-spacing: 0.24px;
}

.form-objectives-update.card {
  margin-bottom: 32px;
}

.form-objectives-update.card:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.06),
    0px 6px 10px 0px rgba(0, 0, 0, 0.042), 0px 1px 18px 0px rgba(0, 0, 0, 0.036);
}
