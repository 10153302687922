.resources .resources-details-card{
    padding: 24px 16px;
}

.resources .resources-details-card__back{
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    padding: 6px 17px;
    border-radius: 3px;
    background: #00708A;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.08);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.42px;
    width: fit-content;
    color: #fff;
    margin-bottom: 32px;
}

.resources .resources-details-card__back:hover{
    opacity: 0.8;
}

.resources .resources-details-card__topic-card{
    border-radius: 3px;
    padding: 0;
    background: #fff;
    border: 1px solid rgba(128, 128, 128, 0.25);
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;
}

.resources .resources-details-card__card{
    padding: 24px 32px 16px 32px;
}

.resources .resources-details-card__card-title{
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

.resources .resources-details-card__topic-cards{
    display: flex;
    flex-wrap: wrap;
    margin-top: 42px;
    gap: 16px;
}

.resources .resources-details-card__body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 24px;
}

.resources .resources-details-card__description{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    max-width: 45%;
}

@media (max-width: 1425px) {
    .resources .resources-details-card__description{
        max-width: 100%;
        padding-bottom: 24px;
    }
}

.resources .resources-details-card__properties{
    display: flex;
    justify-content: space-between;
    gap: 48px;
}

.resources .resources-details-card__property{
}

.resources .resources-details-card__property-title{
    font-size: 14px;
    font-weight: 700;
    line-height: 21px; 
}

.resources .resources-details-card__property-value{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; 
}

.resources .resources-details-card__file{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resources .resources-details-card__footer{
    display: flex;
    justify-content: space-between;
}

.resources .resources-details-card__source{
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
}

.resources .resources-details-card__id{
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
}