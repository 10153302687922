.resources .resources-card__content_item{
    padding: 16px;
    font-size: 14px;
    line-height: 21px;
}

.resources .resources-card{
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.38);
    border-radius: 0px 0px 3px 3px;
    padding: 0 16px;
}

.resources .resources-card__content_item h6{
    margin-bottom: 0;
}

.resources h5.resources-card__content_item{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.18px;
    cursor: pointer;
}

.resources h6.resources-card__content_item{
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
}

.resources .resources-card__content_button{
    margin: 6px;
    padding: 6px;
}