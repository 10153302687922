
.resources .search.inputContainer{
	display: flex;
	position: relative;
	align-items: center;
}

.resources .search .label{
	position: absolute;
	top: -35px;
	opacity: 1;
}

.resources .search .input{
	padding: 1rem 1.8rem;
	margin-bottom: 4rem !important;
	height: 50px !important;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
	border-radius: 5px;
	transition: all 0.3s;
	border: 2px solid white;
	outline: none;

	font-size: 1.3rem;
	line-height: 3rem;
	letter-spacing: normal;
	font-family: "Open Sans", helvetica, arial, sans-serif;
}

.resources .search .input::placeholder {
    opacity: 0.5;
}

.resources .search .input:hover,
.resources .search .input:focus,
.resources .search .input:focus-visible {
    border: 2px solid #185978;
}

.resources .search .inputButton{
	height: 26px;
	width: 26px;
	position: absolute;
	right: 24px;
	top: 14px;
	transition: transform 0.3s;
	transform: scale(1);
}

.resources .search .inputButton.active{
    cursor: pointer;
    transform: scale(1.3);
}

.resources .MuiInput-underline:before{
	border-bottom: none;
}

.resources .MuiInput-underline:after{
	transition: none;
}

.resources .searchIcon{
	cursor: pointer;
}
