.aside {
    overflow: scroll;
    background-color: #fff;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}

.dropDownListContainer {
    margin: 0;
    padding: 0 0 14.8rem 0;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 2.6rem 3.2rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.title {
    /* Style as needed */
}

.closeIcon {
    background: url('path-to-icon-image') no-repeat; /* Replace 'path-to-icon-image' with actual image path */
    width: 20px;
    height: 20px;
    margin: 0.4rem 0 0 0.5rem;
    cursor: pointer;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    gap: 7.4rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 1.6rem 0 1.6rem 5.3rem;
    background: #fff;
    box-shadow: 0 -2px 6px -2px rgba(0, 0, 0, 0.08);
}

.applyFiltersButton {
    border: none;
    text-transform: uppercase;
}

.applyFiltersButton.disabled {
    background-color: #F8F7F6;
    pointer-events: none; 
}

.clearFilter {
    /* Style as needed */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    background: #333;
    opacity: 0.5;
}

.animatedListItem {
    margin: 0;
    list-style: none;
}
