.collapse-element {
  display: flex;
}

.collapse-element-title-wrapper {
  border: 1px solid #eee;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.042), 0px 1px 3px 0px rgba(0, 0, 0, 0.036);
  border-radius: 8px;
  min-width: 150px;
}

.collapse-element-title {
  padding: 0 20px;
  position: absolute;
  width: max-content;
  transform-origin: left;
  font-size: 20px;
  transform: rotate(-90deg);
}

.collapse-element-title h2 {
  color: transparent !important;
}

.collapse-element-title.set h2 {
  color: rgba(0, 0, 0, 0.87) !important;
}

.collapse-element-title-with-btn {
  z-index: 1;
}

.collapse-element-title h2 {
  font-size: 24px;
  text-align: center;
}

.collapse-element-vertical-title {
  display: flex;
  flex: 1;
  justify-content: center;
}

.collapse-element-vertical-title h2 {
  font-size: 24px;
}

.collapse-element-title-icon {
  cursor: pointer;
}

.collapse-element-content {
  flex: 1;
  max-width: 100%;
  height: 100%;
  transition-duration: 0.5s;
  /* overflow: visible; */
  /* padding-bottom: 50px; */
}

.collapse-element-content.show.hide-horizontal {
  overflow-y: visible;
  overflow-x: clip;
}

.collapse-element-content.show {
  width: auto;
  flex: 1;
  max-width: 100%;
}

.collapse-element-content.hide {
  max-width: 0%;
  flex: 1;

  &.hide-horizontal {
    overflow: hidden;
  }

  &.hide-vertical {
    overflow-x: hidden;
  }
}

.collapse-element-vertical-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  position: relative;
}

.collapse-element-vertical-title-wrapper .collapse-element-title-icon {
  position: absolute;
  right: 15px;
  top: 15px;
}
