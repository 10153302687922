.resources .dropDownContainer{
	position: relative;
	list-style: none;
	margin: 0;
	font-size: 16px;
	font-family: "Open Sans", helvetica, arial, sans-serif;
	line-height: 2.8rem;
	font-weight: 400;
}

.resources .dropDownHeader{
	display: flex;
	font-weight: 700;
	font-family: "Open Sans", helvetica, arial, sans-serif;
	font-size: 16px;
	line-height: 1.4rem;
	letter-spacing: 3px;
	text-transform: uppercase;
	white-space: pre;
	cursor: pointer;
	align-items: center;
}

.resources .dropDownHeader.open svg{
	transform: rotate(0deg);
}

.resources .dropDownHeader svg {
    transition: all 0.3s ease;
	transform: rotate(180deg);
}

.resources .dropDownListContainer{
	position: absolute;
	top: 3rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem 2rem 1rem 1rem !important;
	background: white;
	border: 1px solid #c1c1c1;
	border-radius: 3px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
}

.resources .checkBoxContainer{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
	cursor: pointer;
}

.resources .checkBoxContainer input[type='checkbox'] {
    height: 1.2rem !important;
    width: 1.2rem !important;
    margin: 0;
    border-radius: 3px;
}

.resources .checkBoxLabel{
	font-size: 14px;
	line-height: 1.7rem;
	font-family: "Open Sans", helvetica, arial, sans-serif;
	font-weight: 400;
	letter-spacing: normal;
	text-transform: none;
	white-space: pre;
}

.resources .dropdown-container{
	position: absolute;
	padding: 12px 0 12px 32px;
	background-color: #fff;
	width: 260px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
	border-radius: 6px;
	top: 60px;
	left: -32px;
	z-index: 1;
}
