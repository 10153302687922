.resources h1{
    font-size: 32px;
    font-weight: 500;
    line-height: 35px;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 50px;
}

.resources .pagination{
    padding-left: 32px;
    padding-right: 32px;
}

.resources .resource_inventory{
    padding: 20px;
    padding-bottom: 0;
}

.resources h4.title{
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    padding: 16px 32px;
    margin: 0;
}