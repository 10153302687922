.upload-drop-box {
  height: 120px;
  width: 100%;
  border: 2px solid $light-gray;
  border-radius: 4px;
}
.drag-shadow {
  background: $primary;
  box-shadow: 3px 3px 10px rgba($color: #000000, $alpha: 0.2);
}

.form-readonly-title {
  font-size: 13px !important;
  font-weight: 400;
  color: #919191;
}

.form-pdf-title {
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}
