.bordered-date-picker .MuiInputBase-input{
    text-align: center;
}

.form-update .bordered-date-picker .MuiGrid-container{
    width: 166px;
    height: 46px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bordered-date-picker .MuiFormControl-root{
    width: 166px;
    height: 100%;
}

.form-update .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #f5543b !important;
}

.bordered-date-picker .MuiInputBase-root{
    border: 1px solid rgba(128, 128, 128, 0.76);
    border-radius: 4px;
    height: 100%;
}

.bordered-date-picker .MuiInputBase-input{
    height: 46px;
}

.bordered-date-picker .MuiInputBase-root.Mui-error{
    height: 46px;
    border: 1px solid #f5543b;
    border-radius: 4px;
}

.bordered-date-picker .MuiInputBase-root.Mui-disabled.Mui-error{
    height: 46px;
    border: none;
}

.bordered-date-picker .MuiInputBase-root.Mui-disabled{
    border: none;
}


.bordered-date-picker .MuiFormHelperText-root.Mui-error{
    color: #f5543b;
}

.bordered-date-picker.disabled .MuiGrid-container{
    border: none;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
}

.bordered-date-picker .MuiInput-underline:after{
    border: none;
}

.bordered-date-picker .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
}

.bordered-date-picker .MuiInput-underline:not(.Mui-disabled):before {
    border: none;
}

.bordered-date-picker .MuiInput-underline.Mui-disabled:before {
    border: none;
}

.form-update.body .section.no-border{
    border: none;
}

.question.error{
    color: #f5543b;
    font-size: 0.75rem;
}

.form-update *{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.14px;
    white-space: normal;
    padding: 0;
    margin: 0;
    word-break: break-word;
}

.form-update-root{
    padding: 16px;
    margin: 16px;
}

.form-update.body h2.main-title{
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    text-align: center;
}

.form-update.body .section{
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.24);
    background: #FFF;
    padding: 0;
}

.form-update.body .description{
    padding: 24px 32px;
    display: flex  ;
    gap: 54px;
}

.form-update.body .description h3{
    padding-bottom: 0;
}

.form-update.body .title-section.section{
    padding: 24px 32px;
}

.form-update.body .updates{
    background: #FFF;
    display: flex;
    flex-direction: row;
    gap: 54px;
    padding: 24px 32px 48px 32px;
}

.form-update.body .outcomes{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form-update.body .questionAnswers{
    background: #FFF;
    display: flex;
    flex-direction: row;
    gap: 54px;
    padding: 0 32px 64px 32px;
}

.form-update.body .question{
    padding-bottom: 8px;
}

.form-update.body .date-question{
    font-size: 14px;
    font-weight: 600;
}

.form-update.body .questionAnswers:last-child{
    padding-bottom: 40px;
}

.form-update.body .answers{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.form-update.body ol{
    padding-left: 14px;
}

.form-update.body li{
    padding-bottom: 16px;
}

.form-update.body .needs-answer{
    padding: 24px 32px 48px 32px;
}

.form-update.body h3{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    padding-bottom: 12px;
}

.form-update.body h2{
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 83.333% */
    letter-spacing: 0.24px;
}

.form-update.card{
    margin-bottom: 32px;
}

.form-update.card:hover{
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.06), 0px 6px 10px 0px rgba(0, 0, 0, 0.042), 0px 1px 18px 0px rgba(0, 0, 0, 0.036);
}

.form-update .date-pickers{
    gap: 40px;
}
@media (max-width: 1024px){
    .form-update .date-pickers{
        gap: 5px;
        flex-wrap: nowrap;
    }
}
@media (max-width: 768px){
    .form-update .date-pickers{
        gap: 0px;
        flex-wrap: wrap;
    }
}

.form-update .milestone-dates{
    display: flex;
    justify-content: flex-start;
    gap: 22px;
}

.form-update .milestone-dates .subtitle{
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    margin-right: 4px;
}

.form-update .milestone-dates p{
    font-size: 14px;
    font-weight: 700;
}

.form-update .MuiFormHelperText-contained{
    margin-left: 0;
}