.formTextInputPrint{
  width: 100%;
}

.formTextInputPrint .table{
  width: 100%;
  margin: 14px 0;
}

.formTextInputPrint table{
  border-left: 1px solid black;
  border-top: 1px solid black;
  width: calc(100% - 10px);
  border-collapse: collapse;
}

.formTextInputPrint table td{
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding: 5px;
}