@media all {
  .show-in-print {
    display: none;
  }
}

.form-print-subtitle {
  font-size: 12px !important;
  font-weight: 400;
  display: 'flex';
  color: #919191;
  &.pdfProps {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
  }
}

.form-print-subtitle-label {
  font-size: 14px !important;
  font-weight: 400;
  display: 'flex';
  color: #919191;
  margin: 0;
  &.pdfProps {
    font-size: 14px !important;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
  }
}

.form-print-subtitle-medium {
  font-size: 18px !important;
  font-weight: 600;
  display: 'flex';
  color: #919191;
}

.form-print-subtitle-big {
  font-size: 24px;
  font-weight: 'bold';
  text-align: center;
  color: #919191;
}

.form-print-title-small {
  font-size: 16px;
  font-weight: 'bold';
  padding: 10px;
}

.form-print-title-medium {
  font-size: 21px;
  padding: 10px;
}

.form-print-title {
  font-size: 27px;
  padding: 10px;
}

.form-print-title-small.table-print {
  margin-left: -24px;
}

.pdf-mode {
  .table {
    display: flex;
    border: 2px solid rgba(0, 0, 0, 0.87);
    .cell {
      border: 2px solid rgba(0, 0, 0, 0.87);
      flex: 1;
      .subtitle {
        border-bottom: 2px solid rgba(0, 0, 0, 0.87);
        padding: 10px 20px;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
      }
      .value {
        padding: 10px 20px;
      }
    }
  }
  // .subtitle{
  //     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  //     font-weight: 400;
  //     line-height: 1.5;
  //     letter-spacing: 0.00938em;
  //     font-size: 12px;
  //     display: "flex";
  //     color: #919191;
  // }
}

@media print {
  .page-break-before {
    margin-top: 1rem;
    //display: block;
    //background-color: red !important;
    //visibility: hidden;
    break-before: always !important;
    page-break-before: always !important;
  }

  .show-in-print {
    display: block;
  }

  .allow-print-break {
    break-inside: auto !important;
    page-break-inside: auto !important;
  }

  .avoid-print-break {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }
}
