.resources .filterContainer{
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	width: 100%;
}

.resources .filtersTitle{
	padding-top: 0.5rem;
	padding-bottom: 1.5rem;
	font-family: "Archivo", helvetica, arial, sans-serif;
	font-size: 1.5rem;
}

.resources .filterTitle{
	margin: 0;
	font-size: 24px;
	font-weight: 500;
	line-height: 32px;
}

.resources .filtersWrapper{
	display: flex;
	align-items: center;
	gap: 5.9rem;
	margin: 0;
	padding: 0;
	padding-bottom: 4rem;
	z-index: 2;
}

.resources .filters-wrapper{
	padding: 12px 32px;
	background-color: #fff;
	margin: 0;
}

.resources .renderedFiltersContainer{
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	max-width: 1450px;
}

.resources .moreFilters{
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	cursor: pointer;
	border-bottom: 3px solid transparent;
	transition: all 0.4s ease;
	white-space: pre;
	border-radius: 0;
}

.resources .moreFilters:hover {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    border-bottom: 3px solid #a3bdc9;
}


.resources .applyFiltersButton{
	border: none;
	padding: 1.2rem 2.4rem;
	text-transform: uppercase;
}

.resources .applyFiltersButton.disabled{
	background-color: #F8F7F6;
	border: none;
	padding: 1.2rem 2.4rem;
	text-transform: uppercase;
}

.resources .applyFiltersButton.disabled:hover{
	background-color: #F8F7F6;
}

.resources .applyFiltersButton.filtersButton{
	width: fit-content;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
	gap: 1.1rem;
}

.resources .selectedFilters{
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
	padding: 18px 16px;
	font-size: 14px;
	line-height: 1.2rem;
	letter-spacing: 2.5px;
	font-weight: 700;
	font-family: "Archivo", helvetica, arial, sans-serif;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.38);
	border-top: 0.5px solid rgba(0, 0, 0, 0.38);
	background-color: #fff;
}

.resources .selectedFilters.hide{
	background-color: transparent;
	border: none;
}

.resources .selected{
	border-radius: 3px;
	padding: 8px 16px;
	background: #fff;
	border: 1px solid rgba(128, 128, 128, 0.25);
	display: flex;
}

.resources .selectedClose svg{
	cursor: pointer;
	padding-left: 13px;
}

.resources .selectedClose svg:hover path{
	fill: #82b049;
}

.resources .clearSelected.selected{
	border: 1px solid #5DBD94;
	cursor: pointer;
}

.resources .paywallFilter{
	padding: 2.1rem 0;
	border-top: 1px solid #d8d8d8;
}

.resources .paywallFilterLabel{
    font-size: 14px;
    line-height: 1.7rem;
    font-family: "Open Sans", helvetica, arial, sans-serif;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
	white-space: normal;
}

.resources .filterContainerTitleAndSearch{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 32px;
	background-color: #fff;
}
