.dropDownContainer{
	position: relative;
}

.dropDownHeader{
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	font-size: 1.4rem;
	line-height: 1.4rem;
	letter-spacing: 3px;
	text-transform: uppercase;
	white-space: pre;
	cursor: pointer;
}

.dropDownHeader::after {
	content: "";
	width: 13px;
	margin: 4px 0 0 05px;
	transition: all 0.3s ease;
}

.dropDownHeader.open::after {
	transform: rotate(180deg);
}

.dropDownListContainer{
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	padding: 0 0 3rem 3rem;
	margin: 0;
}

.listItem{
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	list-style: none;
	margin: 0;
}

.childListContainer{
	padding-left: 3.8rem;
	display: flex;
	flex-direction: column;
	gap: 11px;
}

.checkBoxContainer{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.2rem;
}

.checkBoxContainer input[type="checkbox"]::before {
	height: 1.8rem !important;
	width: 1.8rem !important;
	margin: 0;
	border-radius: 3px;
	content: ✓;
	display: block;
	transform: translateX(-100%);
}

.checkBoxContainer.parentFilter input[type="checkbox"]::before {
	content: —;
}

.checkBoxContainer.parentFilter.allChildrenSelected input[type="checkbox"]::before {
	content: ✓;
}

.checkBoxContainer input[type="checkbox"]:checked:before {
	transform: translateY(-14%);
}

.parentLabel{
	font-size: 1.6rem;
	letter-spacing: normal;
	line-height: 2.7rem;
	text-transform: none;
	font-family: Open Sans;
	font-weight: 800;
}

.parentLabel.childLabel{
	font-weight: 400;
}
