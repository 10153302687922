.resources{

}

.resources .otherResourcesGrid{
    margin: 16px;
    margin-top: 0;
}

.resources .otherResourcesGrid_title{
    font-size: 28px;
    font-weight: 500;
    line-height: 31px; 
    padding-top: 32px;
    margin-bottom: 16px;
    text-align: center;
}